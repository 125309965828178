var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: false,
              showCheckbox: false,
              "popover-show": false,
              expandOnClickNode: false,
              treeTitle: "作业票类型",
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": "作业票控件",
                },
                on: { "head-add": _vm.headAdd, "head-romve": _vm.handleDelete },
              }),
              _c("grid-head-layout", {
                ref: "searchFrom",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.searchChange,
                  "grid-head-empty": _vm.searchReset,
                },
                model: {
                  value: _vm.searchForm,
                  callback: function ($$v) {
                    _vm.searchForm = $$v
                  },
                  expression: "searchForm",
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  tableOptions: _vm.option,
                  tableData: _vm.tableData,
                  "table-loading": _vm.loading,
                  gridRowBtn: _vm.gridRowBtn,
                  page: _vm.page,
                  "data-total": _vm.page.total,
                },
                on: {
                  "gird-handle-select-click": _vm.selectionChange,
                  "grid-edit": _vm.rowUpdate,
                  "grid-romve": _vm.rowDel,
                  "tree-load": _vm.treeLoad,
                  "grid-addChild": _vm.handleAdd,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }