<template>
    <div>
        <el-container>
            <CommonTree :treeData="treeData" :defaultProps="defaultProps" :searchTitle="searchTitle" :isShowdig="false"
                :showCheckbox="false" :popover-show="false" :expandOnClickNode="false" treeTitle="作业票类型"
                @getNodeClick="treeNodeClick" />
            <el-main>
                <head-layout :head-btn-options="headBtnOptions" head-title="作业票控件" @head-add="headAdd"
                    @head-romve="handleDelete">
                </head-layout>
                <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
                    @grid-head-search="searchChange" @grid-head-empty="searchReset">
                </grid-head-layout>
                <grid-layout ref="gridLayOut" :tableOptions="option" :tableData="tableData" :table-loading="loading"
                    @gird-handle-select-click="selectionChange" :gridRowBtn="gridRowBtn" @grid-edit="rowUpdate"
                    @grid-romve="rowDel" @tree-load='treeLoad' @grid-addChild="handleAdd" :page="page"
                    :data-total="page.total" @page-current-change="onLoad" @page-size-change="onLoad"
                    @page-refresh-change="onLoad">
                </grid-layout>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree";
import { ticketType, ticketControlPage, removeTicketControl, lazyTree } from "@/api/workTicket/index"
export default {
    components: {
        HeadLayout,
        GridLayout,
        CommonTree
    },
    data() {
        return {
            searchColumns: [
                {
                    label: "",
                    prop: "name",
                    type: 'input',
                    search: true,
                    placeholder: this.$t("cip.cmn.rule.inputWarning") + '节点名称',
                },
                {
                    label: "",
                    type: 'input',
                    prop: "enName",
                    search: true,
                    placeholder: this.$t("cip.cmn.rule.inputWarning") + '字段名',
                },
                {
                    label: "",
                    placeholder: this.$t("cip.cmn.rule.inputWarning") + '业务域',
                    prop: "domain",
                    type: 'select',
                    props: {
                      label: "label",
                      value: "value"
                    },
                    dicData: [
                    {
                      label: '文本',
                      value: 'text'
                    },
                    {
                      label: '用户',
                      value: 'user'
                    },
                    {
                      label: '部门',
                      value: 'dept'
                    },
                    {
                      label: '时间',
                      value: 'datePick'
                    },
                  ],
                    search: true,
                },
            ],
            loading: true,
            selectionList: [],
            page: {
                pageSize: 1000,
                currentPage: 1
            },
            tableData: [],
            // 左树
            treeData: [],
            searchTitle: "dictValue",
            defaultProps: {
                label: "dictValue",
                value: "dictKey",
                key: "dictKey",
            },
            category: ''
        };
    },
    computed: {
        ...mapGetters(["userInfo"]),
        headBtnOptions() {
            let buttonBtn = [{
                label: this.$t("cip.cmn.btn.addBtn"),
                emit: "head-add",
                type: "button",
                icon: ""
            }, {
                label: this.$t('cip.cmn.btn.delBtn'),
                emit: "head-romve",
                type: "button",
                icon: ""
            }];
            return buttonBtn;
        },
        // 行按钮添加 add by steve
        gridRowBtn() {
            let buttonBtn = [{
                label: this.$t('cip.cmn.btn.editBtn'),
                emit: "grid-edit",
                type: "text",
                icon: ""
            }, {
                label: this.$t('cip.cmn.btn.addChildBtn'),
                emit: "grid-addChild",
                type: "text",
                icon: ""
            }, {
                label: this.$t('cip.cmn.btn.delBtn'),
                emit: "grid-romve",
                type: "text",
                icon: ""
            }];
            return buttonBtn;
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(",");
        },
        option() {
            return {
                lazy: true,
                tree: true,
                index: false,
                selection: true,
                menuWidth: 300,
                column: [
                    {
                        label: '节点名称',
                        prop: "name",
                        align: "left",
                        width: 240,
                        overHidden: true,
                    },
                    {
                        label: '编码',
                        prop: "code",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '业务域',
                        prop: "domain",
                        align: "center",
                        type: 'select',
                        props: {
                            label: "label",
                            value: "value"
                        },
                        dicData: [
                            {
                                label: '文本',
                                value: 'text'
                            },
                            {
                                label: '用户',
                                value: 'user'
                            },
                            {
                                label: '部门',
                                value: 'dept'
                            },
                            {
                                label: '时间',
                                value: 'datePick'
                            },
                        ],
                        overHidden: true,
                    },
                    {
                        label: '字段名',
                        prop: "enName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '长度',
                        prop: "len",
                        align: "center",
                        overHidden: true,
                    },
                ]
            }
        }
    },
    methods: {
        initTree() {
            ticketType('WORK_TICKET_TYEP').then((res) => {
                this.treeData = res.data.data;
                this.category = this.treeData[0].dictKey;
                this.onLoad(this.page, {});
            })
        },
        treeNodeClick(node) {
            this.category = node.dictKey
            this.page.currentPage = 1;
            this.onLoad(this.page, {});
        },
        headAdd() {
            this.$router.push({
                path: '/jobSlip/fieldSetting/add',
                query: {
                    type: 'add',
                    category: this.category
                }
            })

        },
        handleAdd(row) {
            this.$router.push({
                path: '/jobSlip/fieldSetting/add',
                query: {
                    type: 'addChild',
                    category: this.category,
                    pid: row.id,
                }
            })
        },
        rowUpdate(row) {
            this.$router.push({
                path: '/jobSlip/fieldSetting/add',
                query: {
                    type: 'edit',
                    id: row.id
                }
            })
        },
        rowDel(row) {
            this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
                confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
                cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
                type: "warning"
            })
                .then(() => {
                    return removeTicketControl(row.id);
                })
                .then(() => {
                    this.$message({
                        type: "success",
                        message: this.$t("cip.cmn.msg.success.operateSuccess"),
                    });
                    this.onLoad(this.page)
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
                return;
            }
            this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
                confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
                cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
                type: "warning"
            })
                .then(() => {
                    return removeTicketControl(this.ids);
                })
                .then(() => {
                    // 表格数据重载
                    this.onLoad(this.page);
                    this.$message({
                        type: "success",
                        message: this.$t("cip.cmn.msg.success.operateSuccess"),
                    });
                });
        },
        searchReset(params) {
            params = {}
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
        },
        searchChange(params) {
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
        },
        selectionChange(list) {
            this.selectionList = list;
        },
        onLoad(page, params = {}) {
            this.page = page;
            this.loading = true;
            if (JSON.stringify(params) != "{}") {
                ticketControlPage(page.currentPage, page.pageSize, Object.assign(params, { category: this.category })).then(res => {
                    let data = res.data.data;
                    this.tableData = data.records;
                    // this.page.total = data.total;
                    this.loading = false;
                    this.$refs.gridLayOut.grid.refreshTable()
                    this.$refs.gridLayOut.selectionClear()
                });
            } else {
                lazyTree(page.currentPage, page.pageSize, { pid: 0, category: this.category }).then(res => {
                    let data = res.data.data;
                    this.tableData = data.records;
                    // this.page.total = data.total;
                    this.loading = false;
                    this.$refs.gridLayOut.grid.refreshTable()
                    this.$refs.gridLayOut.selectionClear()
                });
            }

        },
        treeLoad(tree, treeNode, resolve) {
            let page = {};
            page.pageSize = 1000;
            page.currentPage = 1;
            lazyTree(page.currentPage, page.pageSize, { pid: tree.id, category: this.category }).then(res => {
                resolve(res.data.data.records);
            });
        }
    },
    mounted() {
    },
    created() {
        this.initTree();
    },
};
</script>

<style></style>
